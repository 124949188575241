
<template>
<div class="clue-list-index">
            <navigationBar />
        <div class="button-clacss">
             <el-row >
               <el-button size="mini" @click="addOrEditMenu(0)" type="danger">创建</el-button>
               <el-button size="mini" type="danger">主要按钮</el-button>
               <el-button size="mini"  @click="submitExport()"  type="danger">导出</el-button>
             </el-row>
        </div>    
        <div class="table-clacss">
              <!--列表-->
            <el-table :data="tableData" border highlight-current-row  v-loading="listLoading" style="width: 100%; " >
                <el-table-column property="id" label="序号" width="60px"></el-table-column>
                <el-table-column property="name" label="名称"    ></el-table-column>
                 <el-table-column property="age" label="年龄"    ></el-table-column>
                <el-table-column label="操作" width="150px">
                    <template slot-scope="props">
                       <el-button size="mini"  @click="addOrEditMenu(props.row)"  type="mini">编辑</el-button>
                       <el-button size="mini"  @click="submitDelete(props.row.id)"  type="mini">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="Pagination" style="text-align: right;margin-top: 10px;">
                <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="pageNo"
                        :page-size="pageSize"
                         background
                        layout="total, prev, pager, next"
                        :total="this.total">
                </el-pagination>
            </div>
        </div>
      <!--显示-->
        <el-dialog :title="titleMenu" :visible.sync="dialogFormVisible" width="45%" >
        	<el-form ref="form" :model="form" label-width="80px">
        		<el-form-item label="名称">
        			<el-input v-model="form.name"></el-input>
        		</el-form-item>
        		<el-form-item label="年龄">
        			<el-input v-model="form.age"></el-input>
        		</el-form-item>
        	</el-form>
        	<span slot="footer" class="dialog-footer">
        	  <el-button size="small" type="primary" @click="submitForm()">确 定</el-button>
            <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
        	</span>
        </el-dialog>
    </div>
</template>

<script>
import {getTestListInfoPage } from '@/api/getBaseData'
import cookies from '@/utils/cookies/index.js'
import navigationBar from '@/components/navigationBar'
import exportTableToEcxel from '@/utils/excel/exportTableToEcxel.js'
//import exportTableToEcxel from '../../../../../utils/excel/exportTableToEcxel'
  export default {
    methods: {
      tableRowClassName({row, rowIndex}) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },
       //导出
      submitExport:function(){
         //  console.log(tableData);
           let tHeader = ['序号', 'IMSI', 'MSISDN', '证件号码', '姓名'];//标题
           let tableCont = ['ID', 'imsi', 'msisdn', 'address', 'name'];
           let list = [{'ID':1,'imsi':2,'msisdn':3,'address':4,'name':5}];
           let tableName = '月报表';
           exportTableToEcxel(tableName,tHeader,tableCont,list);
           //  this.export2Excel();
      },
　　　 formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]));
      },
       //新建+编辑
      addOrEditMenu:function(e){
            if(e == 0)
            {
                this.titleMenu='新建';    
                this.form = {};
            }else{
                this.titleMenu='编辑'; 
                 this.form.age =e.age;
                 this.form.name =e.name;
            }
            this.dialogFormVisible = true;
      },
      //提交
      submitForm:function(){
          console.log(this.form);
      },
      //删除
      submitDelete:function(e){
            if(this.form =='' || this.form){
                return ;
            }
            console.log(this.form);
      },
      eventED:function(){
      },
      handleCurrentChange:function(pageNo){
           pageNo: this.pageNo;
          this.doQuery();
      },
      
      //加载
      doQuery:function(){
         let para ={
            pageNo: this.pageNo,
            pageSize: this.pageSize,
           // name:this.filters.name,
          };
         getTestListInfoPage(para).then((res)=>{
                  console.log(res,'---');
                  this.tableData = res.list;
                  this.total = res.total;
          },(err)=>{
            this.$message({
              message: '数据请求失败',
              type: 'error'
            });
            this.listLoading = false;
          });
      },
    },
    props:["tebleDateAll","tebleDate","tebleTop"],
    mounted(){
     this.doQuery();
    },
    data() {
      return {
        offset: 0,
        form:{
          name:'',
          age: '',
        },
        pageSize:2,//分页数
        total: 0,//总页数
        pageNo: 0,//点击的值
        listLoading: false,
        tableData:[],//表
        user:{},
        titleMenu:'新建',
        dialogFormVisible:false,
       
      }
    },
    components:{
        navigationBar
    },
  }
</script>
<style lang="scss">
.clue-list-index{
      
}
.el-table .warning-row {
    background: oldlace;
 }

.el-table .success-row {
    background: #f0f9eb;
}
.table-clacss{
  padding: 0.6rem;
  background: #fff;;
}
.button-clacss{
  margin-bottom: 0.5rem;
}
.el-dialog__header {
        background: #E9ECEE;
        padding: 1rem;
    }
    .el-dialog__headerbtn {
        top: 1.25rem;
    }
    .el-dialog__title{
        font-weight: bold;
    }
</style>

