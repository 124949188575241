
function exportTableToEcxel(tableName ,tableTitle,tableCont,jsonData){
    //console.log(jsonData,'------------------------------');
   // console.log(tableName,'------------------------------');
   // console.log(tableTitle,'------------------------------');
   // console.log(tableCont,'------------------------------');
    //要导出的json数据
    // var jsonData = [
    //   {
    //     name:'路人甲',
    //     phone:'123456789',
    //     email:'000@123456.com'
    //   },
    //   {
    //     name:'炮灰乙',
    //     phone:'123456789',
    //     email:'000@123456.com'
    //   },
    //   {
    //     name:'土匪丙',
    //     phone:'123456789',
    //     email:'000@123456.com'
    //   },
    //   {
    //     name:'流氓丁',
    //     phone:'123456789',
    //     email:'000@123456.com'
    //   },
    // ]
    // //列标题，逗号隔开，每一个逗号就是隔开一个单元格
    require.ensure([], () => {
　　　　　　　//　const { export_json_to_excel } = require('../../../../../excel/Export2Excel');
               const { export_json_to_excel } = require('../excel/Export2Excel');
              const tHeader = ['序号', 'IMSI', 'MSISDN', '证件号码', '姓名'];
              const filterVal = ['ID', 'imsi', 'msisdn', 'address', 'name'];
　　　　　　　　const list = [{'ID':1,'imsi':2,'msisdn':3,'address':4,'name':5}];
//                const tHeader = tableTitle;
//                const filterVal = tableCont;
// 　             const list = jsonData;
　　　　　　　　const data = formatJson(filterVal, list);
　　　　　　　　export_json_to_excel(tHeader, data, tableName);//标题
　　　　　　});
}
function formatJson(filterVal, jsonData) {
     return jsonData.map(v => filterVal.map(j => v[j]));
}
export default exportTableToEcxel